import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
    Container, IconButton, InputAdornment, TextField, Button, Typography,
    Snackbar, Alert as MuiAlert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { AuthContext } from '../contexts/AuthContext';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Login = ({ api }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { login } = useContext(AuthContext);
    const { user_type } = useContext(AuthContext);
    const { user_name } = useContext(AuthContext);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [reactivateDialogOpen, setReactivateDialogOpen] = useState(false);
    const [actData, setActData] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const onSubmit = (data) => {
        const formData = new URLSearchParams();
        formData.append('username', data.email.trim());
        formData.append('password', data.password.trim());

        fetch(`${api}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: formData
        })
            .then(response => response.json().then(data => ({ status: response.status, body: data })))
            .then(({ status, body }) => {
                if (status === 200) {
                    setSnackbarMessage('Login successful!');
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true);
                    login(body.access_token);
                    user_type(body.user_type);
                    user_name(body.user_name);
                    navigate('/');
                } else {
                    if (body.detail === 'Your account is deactivated') {
                        setActData(data);
                        setReactivateDialogOpen(true);
                    } else if (body.detail === 'Your account is deactivated, please contact your admin') {
                        let message = 'Your account is deactivated, please contact your admin';
                        setSnackbarMessage(message);
                        setSnackbarSeverity('error');
                        setSnackbarOpen(true);
                    }
                    else {
                        let message = 'Failed to login.';
                        if (body.detail === 'Email not found') {
                            message = 'Email not found.';
                        } else if (body.detail === 'Incorrect password') {
                            message = 'Incorrect password.';
                        }
                        setSnackbarMessage(message);
                        setSnackbarSeverity('error');
                        setSnackbarOpen(true);
                    }
                }
            })
            .catch(error => {
                setSnackbarMessage('Failed to login.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            });
    };

    const handleReactivate = () => {
        const formData = new URLSearchParams();
        formData.append('username', actData.email);
        formData.append('password', actData.password);
        fetch(`${api}/activate/account`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: formData
        })
            .then(response => response.json().then(data => ({ status: response.status, body: data })))
            .then(({ status, body }) => {
                if (status === 200) {
                    setSnackbarMessage('Activation successful!');
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true);
                    login(body.access_token);
                    user_type(body.user_type);
                    user_name(body.user_name);
                    navigate('/');
                } else {
                    if (body.detail === 'Your account is active') {
                        setSnackbarMessage("Your account is active");
                        setSnackbarSeverity('error');
                        setSnackbarOpen(true);
                    } else {
                        let message = 'Failed to activate account.';
                        if (body.detail === 'Email not found') {
                            message = 'Email not found.';
                        } else if (body.detail === 'Incorrect password') {
                            message = 'Incorrect password.';
                        }
                        setSnackbarMessage(message);
                        setSnackbarSeverity('error');
                        setSnackbarOpen(true);
                    }
                }
            })
            .catch(error => {
                setSnackbarMessage('Failed to Activate account.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            });
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleReactivateDialogClose = () => {
        setReactivateDialogOpen(false);
    };

    const handleRegisterIndividual = () => {
        navigate('/register/individual');
        handleDialogClose();
    };

    const handleRegisterOrganization = () => {
        navigate('/register/organization');
        handleDialogClose();
    };

    return (
        <Container sx={{
            paddingTop: "220px",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <Typography variant="h4" gutterBottom>
                Login
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: '300px' }}>
                <TextField
                    label="Email"
                    {...register('email', { required: true })}
                    fullWidth
                    margin="normal"
                    error={!!errors.email}
                    helperText={errors.email ? 'Email is required' : ''}
                />
                <TextField
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    {...register('password', { required: true })}
                    fullWidth
                    margin="normal"
                    error={!!errors.password}
                    helperText={errors.password ? 'Password is required' : ''}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <Box display="flex" justifyContent="space-between" mt={2}>
                    <Button type="submit" variant="contained" color="primary">
                        Login
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={handleDialogOpen}>
                        Register
                    </Button>
                </Box>
            </form>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Select Registration Type</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please choose the type of account you want to create.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRegisterIndividual} color="primary">
                        Individual
                    </Button>
                    <Button onClick={handleRegisterOrganization} color="primary">
                        Organization
                    </Button>
                    <Button onClick={handleDialogClose} color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={reactivateDialogOpen} onClose={handleReactivateDialogClose}>
                <DialogTitle>Reactivate Account</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Your account is deactivated. Would you like to reactivate it?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleReactivate} color="primary">
                        Reactivate
                    </Button>
                    <Button onClick={handleReactivateDialogClose} color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default Login;
