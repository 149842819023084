import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, Snackbar, Typography, Box, MenuItem } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/system';
import RequireAuth from '../components/RequireAuth';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledForm = styled('form')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
}));

const AddReference = ({ api }) => {
    const [description, setDescription] = useState('');
    const [selectedFileId, setSelectedFileId] = useState('');
    const [files, setFiles] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        const fetchFiles = async () => {
            try {
                const token = localStorage.getItem('access_token');
                const response = await fetch(`${api}/files`, {
                    headers: { 'Authorization': `Bearer ${token}` },
                });
                const data = await response.json();
                setFiles(data);
            } catch (error) {
                setSnackbarMessage(`Error fetching files: ${error.message}`);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            }
        };

        fetchFiles();
    }, [api]);

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const handleFileSelect = (e) => {
        setSelectedFileId(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!selectedFileId) {
            setSnackbarMessage('Please select a file.');
            setSnackbarSeverity('warning');
            setSnackbarOpen(true);
            return;
        }

        try {
            const token = localStorage.getItem('access_token');
            const response = await fetch(`${api}/reference/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    description,
                    file_id: parseInt(selectedFileId),
                }),
            });

            if (response.ok) {
                setSnackbarMessage('Reference added successfully!');
                setSnackbarSeverity('success');
            } else {
                const data = await response.json();
                setSnackbarMessage(`Failed to add reference: ${data.detail}`);
                setSnackbarSeverity('error');
            }
        } catch (error) {
            setSnackbarMessage(`Error: ${error.message}`);
            setSnackbarSeverity('error');
        } finally {
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <RequireAuth>
            <Container>
                <Typography variant="h4" gutterBottom>
                    Add Reference
                </Typography>
                <StyledForm onSubmit={handleSubmit}>
                    <TextField
                        label="Description"
                        value={description}
                        onChange={handleDescriptionChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        select
                        label="Select File"
                        value={selectedFileId}
                        onChange={handleFileSelect}
                        fullWidth
                        margin="normal"
                    >
                        {files.map((file) => (
                            <MenuItem key={file.id} value={file.id}>
                                {file.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <Box mt={2}>
                        <Button variant="contained" color="primary" type="submit">
                            Add Reference
                        </Button>
                    </Box>
                </StyledForm>
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Container>
        </RequireAuth>
    );
};

export default AddReference;
