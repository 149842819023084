import React, { useState, useEffect } from 'react';
import {
    Container,
    TextField,
    Button,
    Snackbar,
    Typography,
    Box,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/Delete';
import RequireAuth from '../components/RequireAuth';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const FilesManager = ({ api }) => {
    const [files, setFiles] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [fileToDelete, setFileToDelete] = useState(null);

    useEffect(() => {
        const fetchFiles = async () => {
            try {
                const token = localStorage.getItem('access_token');
                const response = await fetch(`${api}/files`, {
                    headers: { 'Authorization': `Bearer ${token}` },
                });
                const data = await response.json();
                setFiles(data);
            } catch (error) {
                setSnackbarMessage(`Error fetching files: ${error.message}`);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            }
        };

        fetchFiles();
    }, [api]);

    const handleDeleteFile = async () => {
        if (!fileToDelete) return;

        try {
            const token = localStorage.getItem('access_token');
            const response = await fetch(`${api}/file/${fileToDelete.id}/delete`, {
                method: 'DELETE',
                headers: { 'Authorization': `Bearer ${token}` },
            });

            if (response.ok) {
                setSnackbarMessage('File deleted successfully!');
                setSnackbarSeverity('success');
                setFiles(files.filter((file) => file.id !== fileToDelete.id));
            } else {
                const data = await response.json();
                setSnackbarMessage(`Failed to delete file: ${data.detail}`);
                setSnackbarSeverity('error');
            }
        } catch (error) {
            setSnackbarMessage(`Error: ${error.message}`);
            setSnackbarSeverity('error');
        } finally {
            setSnackbarOpen(true);
            setDeleteDialogOpen(false);
        }
    };

    const handleOpenDeleteDialog = (file) => {
        setFileToDelete(file);
        setDeleteDialogOpen(true);
    };

    const handleCloseDeleteDialog = () => {
        setFileToDelete(null);
        setDeleteDialogOpen(false);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbarOpen(false);
    };

    return (
        <RequireAuth>
            <Container>
                <Typography variant="h4" gutterBottom>
                    Manage Files
                </Typography>
                <List>
                    {files.map((file) => (
                        <ListItem
                            key={file.id}
                            secondaryAction={
                                <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={() => handleOpenDeleteDialog(file)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            }
                        >
                            <ListItemText primary={file.name} />
                        </ListItem>
                    ))}
                </List>
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
                <Dialog
                    open={deleteDialogOpen}
                    onClose={handleCloseDeleteDialog}
                    aria-labelledby="delete-dialog-title"
                    aria-describedby="delete-dialog-description"
                >
                    <DialogTitle id="delete-dialog-title">Delete File</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="delete-dialog-description">
                            Are you sure you want to delete the file <strong>{fileToDelete?.name}</strong>? This action
                            cannot be undone.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDeleteDialog} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={handleDeleteFile} color="primary" autoFocus>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </RequireAuth>
    );
};

export default FilesManager;
