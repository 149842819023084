import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Container,
    Typography,
    Snackbar,
    Alert as MuiAlert,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
    DialogContentText,
    DialogTitle,
    Button,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Checkbox
} from '@mui/material';
import RequireAuth from '../components/RequireAuth';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ManageOrganizationUsers = ({ api }) => {
    const [users, setUsers] = useState([]);
    const [subscriptions, setSubscriptions] = useState({});
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const [loading, setLoading] = useState(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [days, setDays] = useState(30);                // Number of days input state (will be replaced by tariff)
    const [price, setPrice] = useState(11900);
    const [dialogOpen, setDialogOpen] = useState(false);
    const navigate = useNavigate();

    const InitiatePayment = async () => {
        const token = localStorage.getItem('access_token');
        const payment_details = {
            phone_number: phoneNumber,
            user_ids: selectedUserIds,
        };

        try {
            const response = await fetch(`${api}/pay/subscription`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(payment_details)
            });

            if (!response.ok) {
                throw new Error('Failed to initiate payment');
            }

            const responseData = await response.json();
            const { transaction_id, status, redirect_url } = responseData;

            if (status === 'PENDING') {
                setSnackbarMessage('Payment initiated successfully. Redirecting...');
                setSnackbarSeverity('success');
                window.open(redirect_url, '_blank');
            } else {
                setSnackbarMessage(`Payment initiation failed with status: ${status}`);
                setSnackbarSeverity('error');
            }
        } catch (error) {
            setSnackbarMessage('Failed to initiate payment');
            setSnackbarSeverity('error');
        } finally {
            setSnackbarOpen(true);
        }
    };

    const fetchUsers = async () => {
        const token = localStorage.getItem('access_token');
        try {
            const response = await fetch(`${api}/organization_users`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const data = await response.json();
            setUsers(data);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const fetchSubscriptions = async () => {
        const token = localStorage.getItem('access_token');
        try {
            const response = await fetch(`${api}/subscriptions/organization-users`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const data = await response.json();
            const subscriptionsByUserId = data.reduce((acc, sub) => {
                acc[sub.user_id] = sub;
                return acc;
            }, {});
            setSubscriptions(subscriptionsByUserId);
        } catch (error) {
            console.error('Error fetching subscription statuses:', error);
        }
    };

    const handleDeactivateUsers = async (user_ids) => {
        const token = localStorage.getItem('access_token');
        try {
            const response = await fetch(`${api}/deactivate/organization_user`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(user_ids)
            });

            if (response.ok) {
                setSnackbarMessage('Users deactivated successfully.');
                setSnackbarSeverity('success');
                fetchUsers();
            } else {
                setSnackbarMessage('Failed to Deactivate users.');
                setSnackbarSeverity('error');
            }
        } catch (error) {
            setSnackbarMessage('Failed to Deactivate users.');
            setSnackbarSeverity('error');
        }
        setSnackbarOpen(true);
    };

    const handleActivateUsers = async () => {
        const token = localStorage.getItem('access_token');
        try {
            const response = await fetch(`${api}/activate/organization_user`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(selectedUserIds)
            });

            if (response.ok) {
                setSnackbarMessage('Users activated successfully.');
                setSnackbarSeverity('success');
                fetchUsers();
            } else {
                setSnackbarMessage('Failed to activate users.');
                setSnackbarSeverity('error');
            }
        } catch (error) {
            setSnackbarMessage('Failed to activate users.');
            setSnackbarSeverity('error');
        }
        setSnackbarOpen(true);
    };



    const handleCreateNewUser = () => {
        navigate('/register/organization_user');
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleDialogConfirmDeactivateUsers = () => {
        handleDeactivateUsers(selectedUserIds);
        handleDialogClose();
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await fetchUsers();
            await fetchSubscriptions();
            setLoading(false);
        };
        fetchData();
    }, [api]);

    const handleCheckboxChange = (userId) => {
        setSelectedUserIds((prev) => {
            if (prev.includes(userId)) {
                return prev.filter((id) => id !== userId);
            } else {
                return [...prev, userId];
            }
        });
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    // Opens the payment dialog
    const handleOpenPaymentDialog = () => {
        setPaymentDialogOpen(true);
    };

    // Closes the payment dialog
    const handleClosePaymentDialog = () => {
        setPaymentDialogOpen(false);
    };


    if (loading) {
        return (
            <Container>
                <CircularProgress />
            </Container>
        );
    }

    return (
        <RequireAuth>
            <Container>
                <Typography variant="h4" gutterBottom>
                    Manage Organization Users
                </Typography>
                <Dialog open={paymentDialogOpen} onClose={handleClosePaymentDialog} fullWidth maxWidth="sm">
                    <DialogTitle>Activate Subscription</DialogTitle>
                    <DialogContent>
                        <Typography variant="body1" gutterBottom>
                            Price: {price} RWF
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Number of days: {days} days
                        </Typography>
                        <TextField
                            label="Phone Number"
                            variant="outlined"
                            fullWidth
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            sx={{ marginTop: '10px' }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={InitiatePayment} color="primary">
                            Proceed to Payment
                        </Button>
                        <Button onClick={handleClosePaymentDialog} color="error">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={dialogOpen} onClose={handleDialogClose}>
                    <DialogTitle>Confirm Deactivation</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to deactivate these users?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleDialogConfirmDeactivateUsers} color="secondary">
                            Deactivate
                        </Button>
                    </DialogActions>
                </Dialog>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCreateNewUser}
                    sx={{ mb: 2 }}
                >
                    Create New User
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenPaymentDialog}
                    disabled={selectedUserIds.length === 0}
                    sx={{ mb: 2, ml: 2 }}
                >
                    Activate Subscription
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleActivateUsers}
                    disabled={selectedUserIds.length === 0}
                    sx={{ mb: 2, ml: 2 }}
                >
                    Activate Users
                </Button>

                <Button
                    variant="contained"
                    color="error"
                    onClick={handleDialogOpen}
                    disabled={selectedUserIds.length === 0}
                    sx={{ mb: 2, ml: 2 }}
                >
                    Deactivate Selected Users
                </Button>

                {users.length === 0 ? (
                    <Typography variant="h6" align="center" sx={{ mt: 4 }}>
                        You have not created any users.
                    </Typography>
                ) : (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Select</TableCell>
                                    <TableCell>User Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Phone</TableCell>
                                    <TableCell>Subscription Status</TableCell>
                                    <TableCell>Account State</TableCell>
                                    <TableCell>Start Date</TableCell>
                                    <TableCell>End Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users.map((user) => {
                                    const subscription = subscriptions[user.id];
                                    return (
                                        <TableRow key={user.id}>
                                            <TableCell>
                                                <Checkbox
                                                    checked={selectedUserIds.includes(user.id)}
                                                    onChange={() => handleCheckboxChange(user.id)}
                                                />
                                            </TableCell>
                                            <TableCell>{user.user_name}</TableCell>
                                            <TableCell>{user.email}</TableCell>
                                            <TableCell>{user.phone || 'N/A'}</TableCell>
                                            <TableCell>
                                                {subscription?.is_active ? (
                                                    <span style={{ color: 'green' }}>Active</span>
                                                ) : (
                                                    <span style={{ color: 'red' }}>Inactive</span>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {user.account_state ? (
                                                    <span style={{ color: 'green' }}>Active</span>
                                                ) : (
                                                    <span style={{ color: 'red' }}>Inactive</span>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {subscription?.start_date
                                                    ? new Date(subscription.start_date).toLocaleDateString()
                                                    : 'N/A'}
                                            </TableCell>
                                            <TableCell>
                                                {subscription?.end_date
                                                    ? new Date(subscription.end_date).toLocaleDateString()
                                                    : 'N/A'}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>)}
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Container>
        </RequireAuth>
    );
};

export default ManageOrganizationUsers;
