import React, { useState } from 'react';
import { Container, TextField, Button, Snackbar, Typography, Box } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import RequireAuth from '../components/RequireAuth';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AddFile = ({ api }) => {
    const [fileName, setFileName] = useState('');
    const [file, setFile] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const MAX_FILE_SIZE = 50 * 1024 * 1024;
        if (file && file.size > MAX_FILE_SIZE) {
            setSnackbarMessage('File size exceeds the 50MB limit.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;
        }
        setFile(file);
    };

    const handleFileNameChange = (e) => {
        setFileName(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!file) {
            setSnackbarMessage('Please select a file.');
            setSnackbarSeverity('warning');
            setSnackbarOpen(true);
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('name', fileName);

        try {
            const token = localStorage.getItem('access_token');
            const response = await fetch(`${api}/file/add`, {
                method: 'POST',
                body: formData,
                headers: { 'Authorization': `Bearer ${token}` },
            });

            if (response.ok) {
                setSnackbarMessage('File added successfully!');
                setSnackbarSeverity('success');
            } else {
                const data = await response.json();
                setSnackbarMessage(`Failed to add file: ${data.detail}`);
                setSnackbarSeverity('error');
            }
        } catch (error) {
            setSnackbarMessage(`Error: ${error.message}`);
            setSnackbarSeverity('error');
        } finally {
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <RequireAuth>
            <Container>
                <Typography variant="h4" gutterBottom>
                    Add File
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="File Name"
                        value={fileName}
                        onChange={handleFileNameChange}
                        fullWidth
                        margin="normal"
                    />
                    <Button
                        variant="contained"
                        component="label"
                    >
                        Upload File
                        <input
                            type="file"
                            hidden
                            accept="application/pdf"
                            onChange={handleFileChange}
                        />
                    </Button>
                    {file && <Typography variant="body2">{file.name}</Typography>}
                    <Box mt={2}>
                        <Button variant="contained" color="primary" type="submit">
                            Add File
                        </Button>
                    </Box>
                </form>
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Container>
        </RequireAuth>
    );
};

export default AddFile;
