import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, Snackbar, Typography, Box, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import RequireAuth from '../components/RequireAuth';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ReferencesManager = ({ api }) => {
    const [references, setReferences] = useState([]);
    const [files, setFiles] = useState([]);
    const [selectedReferenceId, setSelectedReferenceId] = useState('');
    const [description, setDescription] = useState('');
    const [selectedFileId, setSelectedFileId] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        const fetchReferences = async () => {
            try {
                const token = localStorage.getItem('access_token');
                const response = await fetch(`${api}/references`, {
                    headers: { 'Authorization': `Bearer ${token}` },
                });
                const data = await response.json();
                setReferences(data);
            } catch (error) {
                setSnackbarMessage(`Error fetching references: ${error.message}`);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            }
        };

        const fetchFiles = async () => {
            try {
                const token = localStorage.getItem('access_token');
                const response = await fetch(`${api}/files`, {
                    headers: { 'Authorization': `Bearer ${token}` },
                });
                const data = await response.json();
                setFiles(data);
            } catch (error) {
                setSnackbarMessage(`Error fetching files: ${error.message}`);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            }
        };

        fetchReferences();
        fetchFiles();
    }, [api]);

    const handleReferenceChange = async (event) => {
        const referenceId = event.target.value;
        setSelectedReferenceId(referenceId);

        try {
            const token = localStorage.getItem('access_token');
            const response = await fetch(`${api}/reference/${referenceId}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });

            if (response.ok) {
                const data = await response.json();
                setDescription(data.description);
                setSelectedFileId(data.file_id || '');
            } else {
                setSnackbarMessage('Failed to fetch reference details.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            }
        } catch (error) {
            setSnackbarMessage(`Error: ${error.message}`);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const handleFileSelect = (e) => {
        setSelectedFileId(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const token = localStorage.getItem('access_token');
            const response = await fetch(`${api}/reference/${selectedReferenceId}/update`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    description,
                    file_id: parseInt(selectedFileId),
                }),
            });

            if (response.ok) {
                setSnackbarMessage('Reference updated successfully!');
                setSnackbarSeverity('success');
            } else {
                const data = await response.json();
                setSnackbarMessage(`Failed to update reference: ${data.detail}`);
                setSnackbarSeverity('error');
            }
        } catch (error) {
            setSnackbarMessage(`Error: ${error.message}`);
            setSnackbarSeverity('error');
        } finally {
            setSnackbarOpen(true);
        }
    };

    const handleDelete = async () => {
        try {
            const token = localStorage.getItem('access_token');
            const response = await fetch(`${api}/reference/${selectedReferenceId}/delete`, {
                method: 'DELETE',
                headers: { 'Authorization': `Bearer ${token}` },
            });

            if (response.ok) {
                setSnackbarMessage('Reference deleted successfully!');
                setSnackbarSeverity('success');
                setSelectedReferenceId('');
                setDescription('');
                setSelectedFileId('');
                setReferences(references.filter((ref) => ref.id !== selectedReferenceId));
            } else {
                const data = await response.json();
                setSnackbarMessage(`Failed to delete reference: ${data.detail}`);
                setSnackbarSeverity('error');
            }
        } catch (error) {
            setSnackbarMessage(`Error: ${error.message}`);
            setSnackbarSeverity('error');
        } finally {
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbarOpen(false);
    };

    return (
        <RequireAuth>
            <Container>
                <Typography variant="h4" gutterBottom>
                    Manage References
                </Typography>
                <FormControl fullWidth sx={{ marginBottom: 2 }}>
                    <InputLabel>Select Reference</InputLabel>
                    <Select value={selectedReferenceId} onChange={handleReferenceChange}>
                        {references.map((reference) => (
                            <MenuItem key={reference.id} value={reference.id}>
                                {reference.description}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {selectedReferenceId && (
                    <form onSubmit={handleSubmit}>
                        <TextField
                            label="Description"
                            value={description}
                            onChange={handleDescriptionChange}
                            fullWidth
                            margin="normal"
                        />
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Select File</InputLabel>
                            <Select value={selectedFileId} onChange={handleFileSelect}>
                                {files.map((file) => (
                                    <MenuItem key={file.id} value={file.id}>
                                        {file.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Box mt={2} display="flex" justifyContent="space-between" width="100%">
                            <Button variant="contained" color="primary" type="submit">
                                Update Reference
                            </Button>
                            <Button variant="contained" color="secondary" onClick={handleDelete}>
                                Delete Reference
                            </Button>
                        </Box>
                    </form>
                )}
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Container>
        </RequireAuth>
    );
};

export default ReferencesManager;
